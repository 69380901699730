import ProductCard from "../components/ProductCard";
import { Link } from "react-router-dom";

const ProductsFiltered = (props) => {
  const { filteredProducts, handleProductClick } = props;

  return (
    <section className="filtered">
      <ul className="filtered-list">
        {filteredProducts.map((product, index) => (
          <Link to={`/product/${product.id}`} key={index}>
            <ProductCard
              product={product}
              handleProductClick={handleProductClick}
              addToCart={props.addToCart}
            />
          </Link>
        ))}
      </ul>
    </section>
  );
};

export default ProductsFiltered;
