import React from "react";

const About = (props) => {
  return (
    <section className="aboutUs">
      <h2 className="aboutUs__title aboutUs-content">{props.about.title}</h2>

      {props.about.text.map((paragraph, index) => {
        return (
          <p className="aboutUs-content fadeInLeft" key={index}>
            {paragraph}
          </p>
        );
      })}
    </section>
  );
};

export default About;
