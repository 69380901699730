import React, { useState } from "react";
import menuIcon from "../assets/images/menuIcon.svg";

import searchIcon from "../assets/images/searchIcon.svg";
import { Link } from "react-router-dom";
import { logo } from "../assets//data/siteData";
import { SlBasket } from "react-icons/sl";

function NavBar(props) {
  const [sidebarOpen, setSideBarOpen] = useState(false);
  const sidebarClass = sidebarOpen ? "sidebar open" : "sidebar";
  const { handleInputChange, searchProducts, searchTerm, collections } = props;

  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
    if (!sidebarOpen === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  function convertToUpperCase(text) {
    return text.toUpperCase();
  }

  return (
    <section className="navbar-wrapper">
      <section className="navbar">
        <button onClick={handleViewSidebar} className="navbar__btn">
          <img className="navbar__logo " src={menuIcon} alt="logo" />
        </button>

        {/* *************************Mobile****************************** */}
        <div className={sidebarClass}>
          <div className="sidebar__menuItem">
            <Link to="/" onClick={handleViewSidebar}>
              HOME
            </Link>
          </div>

          <div className="sidebar__menuItem">
            <Link to="/featured-collection" onClick={handleViewSidebar}>
              VITAMIN COLLECTIONS
            </Link>
            {collections.map((collection, index) => {
              return (
                <li onClick={handleViewSidebar} key={index}>
                  <Link to={`/featured-collection/${collection}`}>
                    {convertToUpperCase(collection)}
                  </Link>
                </li>
              );
            })}
          </div>
          <div className="sidebar__menuItem">
            <Link to="/about" onClick={handleViewSidebar}>
              ABOUT US
            </Link>
          </div>
          <div className="sidebar__menuItem">
            <Link to="/contact-us" onClick={handleViewSidebar}>
              SUPPORT
            </Link>
            <ul className="sidebar__support">
              <li className="sidebar__item">
                <Link to="/faq" onClick={handleViewSidebar}>
                  FAQ
                </Link>
              </li>
              <li className="sidebar__item">
                <Link to="/contact-us" onClick={handleViewSidebar}>
                  CONTACT US
                </Link>
              </li>
              <li className="sidebar__item">
                <Link to="/refund" onClick={handleViewSidebar}>
                  REFUND POLICY
                </Link>
              </li>
              <li className="sidebar__item">
                <Link to="/terms" onClick={handleViewSidebar}>
                  TERMS OF SERVICE
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <Link to="/">
          <img src={logo} alt="logo" className="navbar__title" />
        </Link>
        <div className="navbar__search">
          <Link to="/cart" className="navbar__menuTablet-basket">
            <SlBasket className="navbar__menuTablet-basket" size="2em" />
          </Link>
        </div>

        {/* ***********************Tablet/Desktop********************************/}
        <div className="navbar__menuTablet">
          <div className="navbar__menuTablet-search">
            <input
              type="text"
              placeholder="Search products..."
              value={searchTerm}
              onChange={handleInputChange}
              className="navbar__menuTablet-search-input"
              id="searchTerm"
            />
            <Link to="/products-filtered">
              <img src={searchIcon} alt="logo" onClick={searchProducts} />
            </Link>
          </div>

          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className="navbar__menuTablet-title fadeInLeft"
            />
          </Link>
          <Link to="/cart" className="navbar__menuTablet-basket">
            <SlBasket className="navbar__menuTablet-basket" size="2em" />
          </Link>
        </div>
      </section>
      <ul className="navbar__menuTablet-list fadeIn">
        <li id="navbar__menuTablet-item ">
          <Link to="/" className="navbar__menuTablet-item">
            HOME
          </Link>
        </li>

        <li id="navbar__menuTablet-item">
          <div className="dropdown">
            <Link to="" className="navbar__menuTablet-item">
              VITAMIN COLLECTIONS
            </Link>
            <div className="dropdown-content">
              {collections.map((collection, index) => {
                return (
                  <Link to={`/featured-collection/${collection}`} key={index}>
                    {convertToUpperCase(collection)}
                  </Link>
                );
              })}
            </div>
          </div>
        </li>
        <li id="navbar__menuTablet-item">
          <Link to="/about" className="navbar__menuTablet-item">
            ABOUT US
          </Link>
        </li>
        <li id="navbar__menuTablet-item">
          <div className="dropdown">
            <div className="dropbtn navbar__menuTablet-item">SUPPORT</div>
            <div className="dropdown-content">
              <Link to="/faq">FAQ</Link>
              <Link to="/contact-us">CONTACT US</Link>
              <Link to="/refund">REFUND POLICY</Link>
              <Link to="/terms">TERMS OF SERVICE</Link>
            </div>
          </div>
        </li>
      </ul>
    </section>
  );
}

export default NavBar;
