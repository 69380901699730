import heroImage from "../images/heroImage.jpeg";
import logo from "../images/logos/logo.png";
const siteName = "Life Pure Essentials";
const email = "lifepureessentials";

const siteInfo = {
  siteName: `${siteName}`,
  mission: `At ${siteName}, the core of our existence lies the profound significance of health for our families and ourselves. With heartfelt conviction, we endeavor to elevate lives, providing top-notch wellness products that resonate emotionally, empowering each individual to unlock their full potential.`,
  email: `support@${email}.com`,
};

const collections = [
  "Anti-Inflammatory",
  "Antioxidant",
  "Blood Health",
  // "Bone & Joint Health",
  "Digestive Health",
  "Eye Health",
  "Hair and Skin",
  "Heart Health",
  "Immune System",
  "Prostate Health",
  "Sleep",
  "Stress",
  "Urinary Health",
  "Weight Loss",
  "All",
];

const faq = [
  {
    q: `How can I contact ${siteName}?`,
    a: `You can email us at ${siteInfo.email} where our customer service team will be happy to help you with whatever you need!`,
  },
  {
    q: "Where do you ship from?",
    a: "We ship from the United Kingdom",
  },
  {
    q: "Can I change or cancel my order?",
    a: "As we aim to process orders as fast as possible, you must request any changes/cancellations within 12 hours of ordering. Unfortunatly all requests after this time cannot be fulfilled. After you recieve your order can be returned for a full refund after it is received.",
  },
  {
    q: "How do I return an item?",
    a: `Pease contact us at ${siteInfo.email} and we will provide you with the information required to return your order`,
  },
  {
    q: "What payment methods do you accept?",
    a: "We accept all major credit cards (VISA, Mastercard, AMEX) and PayPal payments.",
  },
  {
    q: "When will my order be processed?",
    a: "All orders are handled and shipped out from our warehouse. Please allow extra time for your order to be processed during holidays and sale seasons. We process orders between Monday and Friday. Orders will be processed within 1-3 business days from the order date and shipped the next day after the processing day. Please note that we don't ship on weekends.",
  },
  {
    q: "How long will it take to receive my order?",
    a: "Depending on where you live, shipping times can vary. On average, you can expect to receive your order within 2-4 weeks. However, if you live in the United Kingdom, it may be sooner than this. ",
  },
  {
    q: "What if I don't receive my order?",
    a: "If you don't receive your order within 30 days after shipping, you are eligible for a full refund.",
  },
  {
    q: "Will I be charged with customs and taxes?",
    a: "The prices displayed on our site are tax-free in US Dollars, which means you may be liable to pay for duties and taxes once you receive your order. Import taxes, duties and related customs fees may be charged once your order arrives to its final destination, which are determined by your local customs office. Payment of these charges and taxes are your responsibility and will not be covered by us. We are not responsible for delays caused by the customs department in your country. For further details of charges, please contact your local customs office.",
  },
];

const refundPolicy = [
  {
    title: "Refund Policy",
    text: [
      [
        "We have a 30-day return policy, which means you have 30 days after receiving your item to request a return.",
        "To be eligible for a return, your item must be in the same condition that you received it, unopened.",
        `To start a return, you can contact us at ${siteInfo.email}. Please note that returns will need to be sent to the designated address provided. `,
        `Please contact us for any return question at ${siteInfo.email}`,
      ],
    ],
  },
  {
    title: "Damages and issues",
    text: [
      "Please inspect your order upon reception and contact us immediately if the item is defective, damaged, or if you receive the wrong item, so that we can evaluate the issue and make it right.",
    ],
  },

  {
    title: "Exchanges",
    text: [
      "The fastest way to ensure you get what you want is to return the item you have, and once the return is accepted, make a separate purchase for the new item.",
    ],
  },
  {
    title: "European Union 14 day cooling off period",
    text: [
      "Notwithstanding the above, if the merchandise is being shipped into the European Union, you have the right to cancel or return your order within 14 days, for any reason and without a justification. As above, your item must be in the same condition that you received it, unopened. You’ll also need the receipt or proof of purchase.",
    ],
  },
  {
    title: "Refunds",
    text: [
      "We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method within 10 business days. Please remember it can take some time for your bank or credit card company to process and post the refund.",
      `If more than 15 business days have passed since we’ve approved your return, please contact us at ${siteInfo.email}`,
    ],
  },
];

const termsOfService = [
  {
    title: "Terms of Service",
    text: [
      `This website is operated by ${siteName}. Throughout the site, the terms “we”, “us” and “our” refer to ${siteName}. ${siteName} offers this website, including all information, tools and Services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.`,
      "By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.",
      "Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any Services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.",
      "Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.",
    ],
  },
  {
    title: "",
    text: [
      "By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.",
      "You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).",
      "You must not transmit any worms or viruses or any code of a destructive nature.",
      "A breach or violation of any of the Terms will result in an immediate termination of your Services.",
    ],
  },
  {
    title: "General Conditions",
    text: [
      "We reserve the right to refuse Service to anyone for any reason at any time.",
      "You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.",
      "You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the Service is provided, without express written permission by us.",
      "The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.",
    ],
  },

  {
    title: "Accuracy, Completeness and Timeliness of Information",
    text: [
      "We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting professional advice such as doctor. Any reliance on the material on this site is at your own risk.",
      "This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.",
    ],
  },

  {
    title: "Modifications to the Service and Prices",
    text: [
      "Prices for our products are subject to change without notice.",
      "We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.",
      "We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.",
    ],
  },
  {
    title: "Products or Services (if applicable)",
    text: [
      "Certain products or Services may be available exclusively online through the website. These products or Services may have limited quantities and are subject to return or exchange only according to our Refund Policy: https://lifepureessentials.com/refund",
      "We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.",
      "We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or Services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or Service made on this site is void where prohibited.",
      "We do not warrant that the quality of any products, Services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.",
    ],
  },
  {
    title: "Accuracy of Billing and Account Information",
    text: [
      "We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e‑mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.",
      "You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.",
      "For more details, please review our Refund Policy:",
    ],
  },

  {
    title: "Third Party Links",
    text: [
      "Certain content, products and Services available via our Service may include materials from third-parties.",
      "Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or Services of third-parties.",
      "We are not liable for any harm or damages related to the purchase or use of goods, Services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.",
    ],
  },
  {
    title: "User Comments, Feedback and Other Submissions",
    text: [
      "If, at our request, you send certain specific submissions (for example contest entries) or without a request from us, you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.",
      "We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion to be unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.",
      "You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e‑mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.",
    ],
  },

  {
    title: "Errors, Inaccuracies and Omissions",
    text: [
      "Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).",
      "We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.",
    ],
  },
  {
    title: "Prohibited Uses",
    text: [
      "In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.",
    ],
  },
  {
    title: "Disclaimer of Warranties: Limitation of Liability",
    text: [
      "We do not guarantee, represent or warrant that your use of our Service will be uninterrupted, timely, secure or error-free.",
      "We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.",
      "You agree that from time to time we may remove the Service for indefinite periods of time or cancel the Service at any time, without notice to you.",
      "You expressly agree that your use of, or inability to use, the Service is at your sole risk. The Service and all products and Services delivered to you through the Service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.",
      `In no case shall ${siteName}, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, Service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the Service or any products procured using the Service, or for any other claim related in any way to your use of the Service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Service or any content (or product) posted, transmitted, or otherwise made available via the Service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.`,
    ],
  },
  {
    title: "Indemnification",
    text: [
      `You agree to indemnify, defend, and hold harmless ${siteName} and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, Service providers, subcontractors, suppliers, interns, and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.`,
    ],
  },
  {
    title: "Severability",
    text: [
      "In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service. Such determination shall not affect the validity and enforceability of any other remaining provisions.",
    ],
  },
  {
    title: "Termination",
    text: [
      "The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.",
      "These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.",
      "If, in our sole judgment, you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we may also terminate this agreement at any time without notice, and you will remain liable for all amounts due up to and including the date of termination, and/or we may deny you access to our Services (or any part thereof).",
    ],
  },
  {
    title: "Entire Agreement",
    text: [
      "The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.",
      "These Terms of Service and any policies or operating rules posted by us on this site or in respect to the Service constitute the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications, and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).",
      "Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.",
    ],
  },

  {
    title: "Changes to Terms of Service",
    text: [
      "You can review the most current version of the Terms of Service at any time on this page.",
      "We reserve the right, at our sole discretion, to update, change, or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.",
    ],
  },
  {
    title: "Contact Information",
    text: [
      `Questions about the Terms of Service should be sent to us at ${siteInfo.email}`,
    ],
  },
];

const about = {
  title: "About Us",
  text: [
    `At ${siteName}, we're not just another online vitamin store. We are a team of dedicated health enthusiasts who are deeply committed to your well-being. Our mission is clear and simple: to provide you with a carefully curated selection of vitamins and supplements, designed to address specific ailments and empower you on your journey to optimal health.`,
    "What drives us? It's the unwavering belief that good health is the cornerstone of a fulfilling life. We are genuinely passionate about the incredible potential of vitamins and supplements to improve and transform lives. Our team invests countless hours in researching, testing, and categorizing the most effective products that will arrive right at your doorstep.",
    "Why do we categorize vitamins based on ailments? Because we believe in the power of knowledge. We want you to make informed choices about your health. By categorizing our products in a way that clearly outlines the ailments they can address, we aim to empower you with the right information to make decisions that can positively impact your well-being.",
    "But it's not just about the vitamins we offer; it's about the positive impact they can have on your life. We firmly believe that a healthy body and mind can open doors to a life full of vitality and joy. Whether it's a supplement that supports your immune system, or a vitamin that boosts your energy levels, we're here to empower you to live your best life.",
    `What sets us apart from the rest is our unwavering commitment to your health journey. We don't see ourselves as just a store; we see ourselves as your partners in achieving better health. Our knowledgeable team is always ready to answer your questions, provide personalized recommendations, and support you every step of the way.`,
    `At ${siteName}, we refuse to settle for mediocrity. We constantly strive for excellence, which is why we collaborate with trusted brands and manufacturers who share our dedication to quality and innovation. Every product you find on our website has been meticulously selected to meet our stringent standards.`,
    `Join us on this transformative journey towards a healthier you. Explore our website, discover vitamins and supplements that address your specific needs, and let us be your trusted guide to a life of vitality. Whether you're seeking to boost your immune system, improve your heart health, or enhance your overall wellness, we have a solution tailored just for you.`,
    `Thank you for choosing ${siteName}, where passion for well-being and scientific expertise converge. We eagerly anticipate the opportunity to serve you and support you on your path to a healthier, more vibrant life.`,
    `Embrace your journey with us into Life Pure Essentials!`,
  ],
};

const contact = {
  title: "Contact Us",
  text: [
    "We value your feedback, questions, and inquiries. Feel free to reach out to us using the contact information below. We are here to assist you and provide the information you need.",
    "Whether you have a suggestion, a query, or simply want to get in touch, we look forward to hearing from you. We strive to respond to all emails as quickly as possible.",
    "If you have any specific questions or concerns regarding our products, services, or any other aspect of our business, please don't hesitate to reach out. We believe in open communication and are committed to ensuring your experience with us is positive and satisfactory.",
    "Please note that while we make every effort to respond to all emails promptly, it may take us a little longer during peak periods. However, rest assured that your message is important to us, and we will get back to you as soon as we can.",
    "Thank you for visiting our website and considering reaching out to us. We look forward to connecting with you and addressing any inquiries or concerns you may have.",
    "Best regards,",
    `${siteName}`,
  ],
};

const transactionSuccess = [
  {
    title: "Transactions Approved!",
    text: "We are thrilled to inform you that your recent purchase has been successfully processed and approved. Thank you for choosing our online store for your shopping needs. We can't wait for you to receive your merchandise!",
  },
  {
    title: "What Happens Next?",
    text: [
      "Estimated Delivery Time: You can expect to receive your order within 2-4 weeks from today's date. However, if you live in the United Kingdom, it may be sooner than this. We are working diligently to ensure your package arrives promptly and in excellent condition.",
      "Shipment Confirmation: Once your order is dispatched, we will send you a shipment confirmation email with tracking details. You can use the tracking information to stay updated on the progress of your delivery.",
      "Secure Packaging: Your items will be carefully packaged to guarantee their safety during transit. We take great care to ensure that your merchandise arrives in perfect condition, ready for you to enjoy.",
    ],
  },
  {
    title: "Need Help?",
    text: [
      "Should you have any questions or require further assistance regarding your order, please don't hesitate to reach out to our customer support team. We are here to help and provide you with any necessary information.",
    ],
  },
  {
    title: "Thank you for choosing our online store!",
    text: [
      "We appreciate your business and trust in our products. If you have any feedback or suggestions, we would love to hear from you. We hope you enjoy your new purchase and look forward to serving you again in the future.",
    ],
  },
];

const benifits = [
  {
    type: "Immune System",
    description: [
      "A robust immune system is vital for protecting your body against infections and illnesses. It helps you recover faster from ailments, reduces the severity of symptoms, and enhances your overall well-being.",
      "A strong immune system also plays a crucial role in maintaining long-term health and resilience to various health challenges.",
    ],
  },
  {
    type: "Anti-Inflammatory",
    description: [
      "Anti-inflammatory vitamins are essential for promoting a healthy body and mitigating the risk of chronic inflammation. These vitamins aid in reducing inflammation throughout the body, contributing to a faster recovery from inflammatory conditions and minimizing their symptoms.",
      "By incorporating these vitamins into your diet, you can enhance your overall well-being and bolster your body's resilience against inflammation-related health issues. Furthermore, a consistent intake of anti-inflammatory vitamins is key to maintaining long-term health and ensuring your body's ability to combat inflammation effectively.",
    ],
  },
  {
    type: "Antioxidant",
    description: [
      "Antioxidant vitamins are essential for safeguarding your body against oxidative stress and damage. They play a pivotal role in accelerating your recovery from oxidative stress-related conditions, lessening the extent of damage, and improving your overall health. ",
      "By incorporating these vitamins into your daily regimen, you can bolster your body's resilience against oxidative stress and its associated health challenges. Additionally, a consistent intake of antioxidant vitamins is paramount for maintaining long-term health and ensuring your body's ability to combat oxidative stress effectively.",
    ],
  },
  {
    type: "Blood Health",
    description: [
      "Optimal blood health is paramount for your body's overall well-being and resilience to various health challenges. Blood health improvement vitamins are essential in supporting the proper functioning of your circulatory system, aiding in faster recovery from blood-related ailments, and reducing the severity of associated symptoms. ",
      "They contribute significantly to your long-term health by ensuring that your blood remains in top condition, which, in turn, fortifies your body's capacity to handle a wide range of health challenges. A focus on these vitamins in your diet or supplementation regimen can make a substantial difference in promoting a healthy circulatory system and, by extension, your overall health.",
    ],
  },
  {
    type: "Bone & Joint Health",
    description: [
      "Maintaining strong and healthy bones and joints is crucial for overall well-being and resilience against various health challenges. Bone and joint health vitamins are essential for supporting your skeletal system, aiding in faster recovery from bone and joint-related issues, and reducing the severity of discomfort and mobility limitations. ",
      "These vitamins play a pivotal role in long-term health by ensuring the integrity and strength of your bones and joints, which are the foundation of your body's movement and stability. Focusing on these vitamins in your diet or supplementation can make a substantial difference in promoting robust bone and joint health, ultimately enhancing your overall physical well-being and resilience to a range of health concerns",
    ],
  },
  {
    type: "Depression and Heart Health",
    description: [
      "Maintaining good mental health and heart health is essential for overall well-being and resilience against various health challenges. Depression and heart health vitamins are crucial in supporting emotional well-being and cardiovascular function, helping individuals recover more effectively from emotional and heart-related issues, and reducing the severity of symptoms. ",
      "These vitamins play a pivotal role in long-term health by ensuring emotional balance and heart vitality, which are fundamental aspects of overall health and resilience. Prioritizing these vitamins in your diet or supplementation can make a significant difference in promoting emotional well-being and heart health, ultimately enhancing your overall health and resilience to a range of health concerns.",
    ],
  },
  {
    type: "Digestive Health",
    description: [
      "Maintaining a healthy digestive system is vital for overall well-being and resilience against various health challenges. Digestive health vitamins are essential for supporting efficient digestion, aiding in quicker recovery from digestive issues, and reducing the severity of discomfort and digestive disturbances. ",
      "These vitamins play a pivotal role in long-term health by ensuring the optimal function of your digestive system, which is the cornerstone of nutrient absorption and overall health. Focusing on these vitamins in your diet or supplementation can significantly contribute to promoting digestive well-being, enhancing your overall health, and strengthening your resilience to a range of health concerns.",
    ],
  },
  {
    type: "Energy and Brain Health",
    description: [
      "Sustaining optimal energy levels and brain health is fundamental for overall well-being and resilience against various health challenges. Energy and brain health vitamins are essential in supporting mental acuity, cognitive function, and maintaining consistent energy levels, aiding individuals in managing mental fatigue and enhancing overall brain health. ",
      "These vitamins are indispensable for long-term health by ensuring that your brain functions optimally and that you have the energy to meet daily demands, thus improving your overall vitality. Prioritizing these vitamins in your diet or supplementation can significantly boost your cognitive well-being, sustain your energy levels, and bolster your resilience to a range of health concerns.",
    ],
  },
  {
    type: "Eye Health",
    description: [
      "Preserving good eye health is crucial for overall well-being and resilience against various vision-related health challenges. Eye health vitamins are essential for supporting visual acuity, protecting against age-related eye conditions, and reducing the severity of vision-related symptoms.",
      "These vitamins are pivotal for long-term health by ensuring that your eyes function optimally, allowing you to maintain clear vision and eye health as you age. Focusing on these vitamins in your diet or supplementation can make a significant difference in promoting eye health, enhancing your overall visual well-being, and fortifying your resilience to a range of vision-related health concerns.",
    ],
  },
  {
    type: "Hair and Skin",
    description: [
      "Maintaining healthy hair and skin is vital for overall well-being and resilience against various cosmetic and dermatological concerns. Hair and skin health vitamins are essential for promoting hair growth, skin elasticity, and reducing the severity of skin-related issues. ",
      "These vitamins are crucial for long-term health by ensuring that your hair remains strong and your skin stays radiant and youthful. Prioritizing these vitamins in your diet or supplementation can significantly contribute to enhancing the appearance and health of your hair and skin, ultimately bolstering your overall well-being and confidence in facing a range of cosmetic and dermatological challenges",
    ],
  },
  {
    type: "Heart Health",
    description: [
      "Preserving heart health is paramount for overall well-being and resilience against various cardiovascular challenges. Heart health vitamins are essential for supporting optimal heart function, reducing the risk of heart-related issues, and minimizing the severity of cardiovascular symptoms. ",
      "These vitamins are pivotal for long-term health by ensuring that your heart operates at its best, helping you maintain a healthy cardiovascular system as you age. Prioritizing these vitamins in your diet or supplementation can make a significant difference in promoting heart health, enhancing your overall cardiovascular well-being, and fortifying your resilience to a range of heart-related health concerns.",
    ],
  },
  {
    type: "Prostate Health",
    description: [
      "Maintaining good prostate health is crucial for overall well-being and resilience against various prostate-related health challenges. Prostate health supplements and lifestyle choices can play an essential role in supporting the proper function of the prostate gland, reducing the risk of prostate issues, and minimizing the severity of associated symptoms. ",
      "These measures are pivotal for long-term health by ensuring that your prostate remains healthy and that you can maintain good urinary and reproductive function as you age. Prioritizing prostate health through a balanced diet, regular exercise, and appropriate supplements can significantly contribute to promoting a healthy prostate, enhancing your overall well-being, and fortifying your resilience to a range of prostate-related health concerns.",
    ],
  },
  {
    type: "Sleep",
    description: [
      "Getting sufficient and quality sleep is fundamental for overall well-being and resilience against a wide range of health challenges. Sleep is a critical time for the body and mind to rest, recover, and rejuvenate. Adequate sleep helps improve cognitive function, emotional well-being, and physical health. It enhances memory, boosts the immune system, and supports cardiovascular health. Prioritizing healthy sleep habits, with good vitamin and minieral nutrition will help to maintain a regular sleep schedule, creating a comfortable sleep environment, and managing stress, can significantly contribute to promoting restful and restorative sleep, enhancing your overall well-being, and strengthening your resilience to various health concerns.",
    ],
  },
  {
    type: "Stress",
    description: [
      "Managing stress is essential for overall well-being and resilience against the negative effects of chronic stress on both physical and mental health. While there are no specific stress vitamins, several vitamins and minerals play a crucial role in supporting the body's response to stress and promoting emotional well-being.",
      "These include B vitamins, particularly B-complex vitamins such as B6, B9 (folate), and B12, which are involved in neurotransmitter production and stress hormone regulation. Additionally, vitamin C, magnesium, and zinc can support the body's stress response by reducing oxidative stress and supporting the immune system. While vitamins and minerals are important, it's also essential to adopt stress management strategies like exercise, relaxation techniques, and a balanced diet to effectively address and reduce stress in your life.",
    ],
  },
  {
    type: "Urinary Health",
    description: [
      "Maintaining good urinary health is vital for overall well-being and resilience against various urinary tract and bladder-related issues. While there aren't specific urinary health vitamins, several vitamins and nutrients can play a supportive role in promoting urinary health.",
      "These include vitamin C, which can help acidify urine and reduce the risk of urinary tract infections, and vitamin D, which may play a role in overall urological health. Additionally, staying hydrated is crucial for urinary health, so consuming plenty of water and maintaining proper fluid balance is essential. It's also advisable to adopt a diet rich in antioxidants and maintain a healthy lifestyle to support your urinary system. If you have specific concerns about urinary health, consulting a healthcare professional is advisable to determine the best approach for your individual needs.",
    ],
  },
  {
    type: "Weight Loss",
    description: [
      "While there are no vitamins that can directly cause weight loss, certain vitamins and nutrients can play a supportive role in a weight loss journey when combined with a healthy diet and regular exercise.",
      "Vitamin D is one such nutrient, as adequate levels are associated with better weight management. Additionally, it can support overall health, making it easier to engage in physical activity. B-complex vitamins, including B6, B12, and folate, are also beneficial. They play a role in metabolism and energy production, helping you stay active and burn calories effectively.",
      "Calcium can contribute to weight management by influencing how your body stores and burns fat, while iron supports the transport of oxygen in the body, crucial for maintaining energy levels during physical activity. Dietary fiber, although not a vitamin, can be instrumental in curbing overeating by promoting feelings of fullness.",
      "Chromium may assist in regulating blood sugar levels and reducing cravings for sugary or high-carbohydrate foods, while omega-3 fatty acids support overall health and may help reduce inflammation, often linked to weight gain.",
      "It's essential to emphasize that vitamins alone won't lead to significant weight loss. A comprehensive weight loss plan should encompass a balanced diet, regular exercise, sufficient sleep, and effective stress management. Before embarking on any weight loss program or supplement regimen, it's prudent to consult with a healthcare professional to ensure that it aligns with your specific needs and is safe for you.",
    ],
  },
  {
    type: "All",
    description: [
      "Taking vitamins is crucial for maintaining good health as they support bodily functions, prevent deficiencies, and offer various benefits. Nutrient gaps, caused by factors like soil quality and dietary preferences, make it challenging to obtain all essential vitamins from food alone.",
      "Vitamins fill these gaps, ensuring optimal functioning and reducing deficiency-related health issues. They also support immune function, enhance energy through B vitamins, and contribute to long-term health by combating free radicals. Vitamins should complement a balanced diet, and consulting a healthcare professional before starting supplements is advisable.",
    ],
  },
];

export {
  siteInfo,
  faq,
  refundPolicy,
  termsOfService,
  about,
  contact,
  transactionSuccess,
  heroImage,
  logo,
  collections,
  benifits,
};
