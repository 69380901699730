import { AddQuantityBtn } from "../components";
import { FaRegTrashCan } from "react-icons/fa6";
import { CTAButton } from "../components";

function Cart(props) {
  const { cart, increment, decrement, removeItem } = props;

  const calculateTotalPrice = (cart) => {
    let totalPrice = 0;
    cart.forEach((item) => {
      const { salePrice, quantity } = item;
      totalPrice += salePrice * quantity;
    });
    return totalPrice;
  };

  return (
    <section className="cart">
      <h1 className="cart-title">Your Cart</h1>

      {cart.length !== 0 ? (
        <section className="cart-items">
          {cart.map((item, index) => {
            return (
              <section className="cart-item-wrapper" key={index}>
                <section className="cart-item">
                  <img
                    src={item.image}
                    alt="product"
                    className="cart-itemInfo__img"
                  />

                  <div className="cart-itemInfo__detail">
                    <h3 className="cart-itemInfo__name">{item.name}</h3>
                    <p className="cart-itemInfo__unitprice">{item.salePrice}</p>
                  </div>
                  <section className="cart-itemquantity">
                    <AddQuantityBtn
                      quantity={item.quantity}
                      increment={increment}
                      decrement={decrement}
                      item={item}
                    />
                    <FaRegTrashCan
                      className="cart-itemquantity__empty"
                      onClick={() => removeItem(item)}
                    />
                  </section>
                </section>
                <p className="cart-itemquantity__totalprice">
                  <span>&#163;</span>{" "}
                  {(item.salePrice * item.quantity).toFixed(2)}
                </p>
              </section>
            );
          })}

          <section className="cart-checkoutCTA">
            <h3 className="cart-subtotal">
              Subtotal:
              <p className="cart-subtotal__amt">
                <span>&#163;</span>
                {calculateTotalPrice(cart).toFixed(2)}
              </p>
            </h3>
            <form
              action="https://mgdserver-905bec944675.herokuapp.com/checkout-lifepureessentials/create-checkout-session"
              method="POST"
              id="checkout-form"
            >
              <input type="hidden" name="cart" value={JSON.stringify(cart)} />

              <CTAButton type="submit" text={"Checkout"} />
            </form>
          </section>
        </section>
      ) : (
        <section className="cart-items">Cart Is Empty</section>
      )}
    </section>
  );
}

export default Cart;
