import { heroImage } from "..//assets//data//siteData";
import { ProductCard } from "../components";
// import ReactGA from "react-ga4";

import { Link } from "react-router-dom";

// ReactGA.initialize("G-54H54WFPYN");
//
// ReactGA.send({ hitType: "pageview", page: window.location.pathname });

function Main(props) {
  return (
    <section className="main">
      <img src={heroImage} alt="hero" className="main__hero" />
      <section className="main__content fadeInLeft">
        <h2 className="main__title">FEATURED COLLECTION</h2>
        <h2 className="main__title">{props.featuredCollectionCat}</h2>

        <section className="main__cards fadeInUp">
          {props.productInfo.map((product, index) => {
            return (
              <Link
                to={`/product/${product.id}`}
                key={index}
                onClick={() => props.pageReset()}
              >
                <ProductCard
                  product={product}
                  addToCart={props.addToCart}
                  handleProductClick={props.handleProductClick}
                />
              </Link>
            );
          })}
        </section>
      </section>
    </section>
  );
}

export default Main;
