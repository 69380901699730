function Terms(props) {
  return (
    <>
      <div className="terms">
        <section className="terms-content fadeInLeft">
          <h2 className="terms-title">{props.terms[0].title}</h2>
          {props.terms.map((item, key) => {
            return (
              <div key={key}>
                {item.text.map((text, key) => {
                  return (
                    <p className="terms-paragraph" key={key}>
                      {text}
                    </p>
                  );
                })}
              </div>
            );
          })}
        </section>
      </div>
    </>
  );
}

export default Terms;
