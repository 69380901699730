import { AddToCart } from "../components";

const ProductCart = (props) => {
  const { product } = props;

  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  }

  const applyDiscount = product.salePrice / 1.15;
  const salePrice = applyDiscount.toFixed(2);

  return (
    <div
      className="main__card"
      onClick={() => props.handleProductClick(product)}
    >
      <img src={product.media[0]} alt="product" className="main__img" />
      <h3 className="main__name">{truncateString(product.name, 35)}</h3>
      <div className="main__prices">
        <p className="main__regprice">
          <span>&#163;</span>
          {product.salePrice}
        </p>
        <p className="main__saleprice">
          <span>&#163;</span>
          {salePrice}
        </p>
      </div>
      <AddToCart
        addToCart={props.addToCart}
        product={product}
        salePrice={salePrice}
      />
    </div>
  );
};

export default ProductCart;
