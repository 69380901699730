import ProductCard from "../components/ProductCard";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { benifits } from "../assets/data/siteData";

const Collections = (props) => {
  const { handleProductClick, addToCart, productInfo } = props;

  const [benifitsDescription] = useState(benifits);
  const [collectionArr, setCollectionArr] = useState([]);

  const { collection } = useParams();

  useEffect(() => {
    // If selection is all, then displays all products
    if (collection === "All") {
      setCollectionArr(productInfo);
    } else {
      // //Array of products selected from collection category
      const collectionFilter = productInfo.filter((products) => {
        return products.collection.includes(collection);
      });
      setCollectionArr(collectionFilter);
    }
  }, [collection, productInfo]);

  //Selects the correct description based on the category
  const descriptionSummary = benifitsDescription.filter(
    (description) => description.type === collection
  );

  const desriptionInfo = descriptionSummary[0];

  return (
    <>
      <section className="main__collection">
        {collection && <h2 className="main__title">{collection}</h2>}
        {!collection && (
          <h2 className="main__title">General Health Vitamin Requirements</h2>
        )}
        {desriptionInfo &&
          desriptionInfo.description.map((paragraph, index) => (
            <p key={index} className="main__description">
              {paragraph}
            </p>
          ))}
        {!desriptionInfo && (
          <>
            <p className="main__description">
              Taking vitamins is crucial for maintaining good health as they
              support bodily functions, prevent deficiencies, and offer various
              benefits. Nutrient gaps, caused by factors like soil quality and
              dietary preferences, make it challenging to obtain all essential
              vitamins from food alone.
            </p>
            <p className="main__description">
              Vitamins fill these gaps, ensuring optimal functioning and
              reducing deficiency-related health issues. They also support
              immune function, enhance energy through B vitamins, and contribute
              to long-term health by combating free radicals. Vitamins should
              complement a balanced diet, and consulting a healthcare
              professional before starting supplements is advisable.
            </p>
          </>
        )}

        <div className="main__cards ">
          {collectionArr.map((product) => (
            <Link to={`/product/${product.id}`} key={product.id}>
              <ProductCard
                product={product}
                addToCart={addToCart}
                handleProductClick={handleProductClick}
              />
            </Link>
          ))}
        </div>
      </section>
    </>
  );
};
export default Collections;
