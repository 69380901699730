function AddQuantityBtn(props) {
  const { quantity, increment, decrement, item } = props;

  return (
    <section className="product__addToCart">
      <div className="product__actions">
        <div className="product__quantityBtn" onClick={() => decrement(item)}>
          -
        </div>
        <p className="product__quantity">{quantity}</p>
        <div className="product__quantityBtn" onClick={() => increment(item)}>
          +
        </div>
      </div>
    </section>
  );
}

export default AddQuantityBtn;
