import { useParams } from "react-router-dom";
import React, { useEffect } from "react";

const TransSucess = (props) => {
  const { approved } = useParams();

  useEffect(() => {
    localStorage.removeItem("cart");
  }, [approved]);

  return (
    <section className="transSucess">
      <div>
        <h1 className="transSucess-title">
          {props.transactionSuccess[0].title}
        </h1>
        <p className="transSucess-content">
          {props.transactionSuccess[0].texts}
        </p>
        <h2 className="transSucess-subtitle">
          {props.transactionSuccess[1].title}
        </h2>
        <ul>
          {props.transactionSuccess[1].text.map((text, index) => {
            return (
              <li className="transSucess-content" key={index}>
                {text}
              </li>
            );
          })}
        </ul>
        <h2 className="transSucess-subtitle">
          {props.transactionSuccess[2].title}
        </h2>
        <p className="transSucess-content">
          {props.transactionSuccess[2].text}
        </p>
        <h3 className="transSucess-subtitle">
          {props.transactionSuccess[3].title}
        </h3>
        <p className="transSucess-content">
          {props.transactionSuccess[3].text}
        </p>
      </div>
    </section>
  );
};

export default TransSucess;
