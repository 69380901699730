import { Link } from "react-router-dom";
function AddToCart(props) {
  const { product, salePrice } = props;
  const quantity = 1;
  const item = { ...product, quantity, salePrice };

  return (
    <Link to="/cart">
      <button className="addtocart" onClick={() => props.addToCart(item)}>
        Add To Cart
      </button>
    </Link>
  );
}

export default AddToCart;
