import { useParams } from "react-router-dom";
import { AddQuantityBtn, CTAButton } from "../components";
import { useState } from "react";
import { Link } from "react-router-dom";

function Product(props) {
  const productId = useParams();
  const [quantity, setQuantity] = useState(1);

  const increment = (e) => {
    setQuantity(quantity + 1);
  };
  const decrement = (e) => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    } else if (quantity === 1) {
      setQuantity(1);
    }
  };

  const product = props.productInfo.find(
    (element) => element.id.toString() === productId.productId
  );

  const selectedProduct = { ...product, quantity: quantity };
  const applyDiscount = product.salePrice / 1.15;
  const salePrice = applyDiscount.toFixed(2);
  console.log(product.item);
  return (
    <section className="product">
      <section className="product__images">
        {product.media.map((item, index) => {
          return (
            <img
              key={index}
              src={item}
              alt="product"
              className="product__img fadeInLeft"
            />
          );
        })}
      </section>
      <section className="product__info fadeInLeft">
        <h2 className="product__name">{product.name}</h2>
        <div className="product__prices">
          <p className="product__regprice">
            <span>&#163;</span>
            {product.salePrice}
          </p>
          <p className="product__saleprice">
            <span>&#163;</span>
            {salePrice}
          </p>
        </div>
        <article className="product__shippingDisclaimer">
          <p className="underline">Shipping </p>calculated at checkout
        </article>
        <h4 className="product__quantityTitle">Quantity</h4>
        <AddQuantityBtn
          quantity={quantity}
          increment={increment}
          decrement={decrement}
        />
        <Link to={"/cart"} onClick={() => props.addToCart(selectedProduct)}>
          <CTAButton text={"Add To Cart"} />
        </Link>
        <h2 className="product__serving">{selectedProduct.serving}</h2>
        <ul className="product__description">
          {selectedProduct.description.map((item, index) => {
            return (
              <li className="product__descriptionTxt--benefit" key={index}>
                {item}
              </li>
            );
          })}
        </ul>
        <h2 className="product__ingredients-title">Ingredients</h2>
        <ul className="product__description">
          {selectedProduct.ingredients.map((ingredients) => {
            return (
              <li
                className="product__descriptionTxt--benefit"
                key={ingredients}
              >
                {ingredients}
              </li>
            );
          })}
        </ul>
      </section>
    </section>
  );
}

export default Product;
